







import Vue from 'vue';
import {defineComponent} from '@nuxtjs/composition-api';
import * as SiteConfig from '~/app/siteConfig.js';

export default defineComponent({
  name: 'LocalBusiness',
  data() {
     const businessJsonLd = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": SiteConfig.siteName,
      "url": SiteConfig.siteBaseUrl,
      "telephone": SiteConfig.contactNumber,
      "email": SiteConfig.contactEmail,
      "logo": SiteConfig.siteLogo,
      "sameas":
        [ SiteConfig.facebookLink,
          SiteConfig.instagramLink,
          SiteConfig.twitterLink,
          SiteConfig.pinterestLink,
          SiteConfig.houzzLink
        ],
       "address": {
         "@type" : "PostalAddress",
         "streetAddress" : SiteConfig.street,
         "addressLocality" : SiteConfig.town,
         "addressRegion" : SiteConfig.region,
         "postalCode" : SiteConfig.postcode,
         "addressCountry" : SiteConfig.country,
       },
       "openingHoursSpecification": [
         {
           "@type": "OpeningHoursSpecification",
           "dayOfWeek": SiteConfig.daysOpen,
           "opens": SiteConfig.opensAt,
           "closes": SiteConfig.closesAt
         }]
    }

    return {
      businessJsonLd
    }
  }

});
